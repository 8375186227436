import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "timeTable", "timeHour", "timeMin", "seconds" ];
    second_toggle;

     refresh(targets) {
        fetch("abfahrtsmonitors/refresh", { headers: { "Accept": "application/json" } })
        .then((response) => {
            // console.log(response);
            return response.json();
        })
        .then(data => {
            targets.find("timeTable").innerHTML = data.html;
            targets.find("timeHour").innerHTML = data.time_hour;
            targets.find("timeMin").innerHTML= data.time_min;
        });
    }

    refresh_seconds(targets) {
        if(this.second_toggle === 1) {
            targets.find("seconds").style.visibility= "hidden";
            this.second_toggle = 0
        }
        else {
            targets.find("seconds").style.visibility= "visible";
            this.second_toggle = 1
        }
    }

    connect() {
        setInterval( this.refresh ,60000, this.targets);
        setInterval( this.refresh_seconds ,1000, this.targets);
    }
}

